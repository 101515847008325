
import { mapGetters } from "vuex";
import GoodBanner from "../components/goodHome/goodBanner.vue";
import { fromatCDNPath } from "../assets/js/filter";
export default {
  components: { GoodBanner },
  data() {
    return {
      fromatCDNPath,
      price_list: [5, 10, 20, 50, 100, 250, 500, 1500, 3000],
      tabData: [],
      search_text: "",
      max_price: "",
      anc: 0,
    };
  },
  computed: {
    ...mapGetters(["getCommonHistoryHeight", "getUserinfo"]),
    ...mapGetters("battle", ["getRooms"]),
    model1List() {
      this.$store.commit("battle/INIT_ROOM_TRANS");
      return this.getRooms;
    },
    filtterBox() {
      if (this.tabData.length == 0) {
        return []
      }
      if (!this.tabData[this.anc]) {
        return []
      }
      let items = this.tabData[this.anc].box_infos.filter((item) => {
        let isNameMatch =
          !this.search_text ||
          item.box_name.includes(this.search_text) ||
          item.price == this.search_text * 10000;
        let isPriceLowerThanMax = !this.max_price || item.price == this.max_price * 10000;

        return isNameMatch && isPriceLowerThanMax;
      });

      return items;
    },
  },
  created() {},
  mounted() {
    // console.error(this.tabData);
    this.getHomeBox()
    // 是否从steam登陆过来
    if (window.location.href.indexOf("openid") !== -1) {
      let url = window.location.href;
      this.login({ call_back_url: url });
    }
    this.getPkRoomList({
      // 分页信息
      page_no: 1,
      page_size: 4,
    });
  },
  beforeDestroy() {},
  methods: {
    getHomeBox(){
      //箱子相关
      let _sendDataA = {
        column_type: 1,
      };
      this.$getHomeBox(_sendDataA).then((res) => {
        let tempTabData = [];
        let array = res.data.infos || [];
        array.sort((a, b) => {
          return b.show_order - a.show_order;
        });
        for (let index = 0; index < array.length; index++) {
          let box_infos = array[index].box_infos || [];
          box_infos.sort((a, b) => {
            return b.weight - a.weight;
          });
          array[index].box_infos = box_infos;
        }
        tempTabData = array;
        if (false) { // 去掉全部
          let allBox = [];
          array.forEach((item) => {
            allBox.push(...item.box_infos);
          });
          let data = {
            bgm: "[]",
            box_infos: allBox,
            id: 10,
            img: "",
            show_order: 1,
            title: "全部",
            weight: 0,
          };
          tempTabData.unshift(data);
        }
        this.tabData = tempTabData;
      }).catch((err)=>{
        console.log(err);
      })
    },
    choosemax(price) {
      this.max_price = this.max_price == price ? "" : price;
    },
    // 跳转至开箱界面
    handleClick(id) {
      this.$router.push({
        path: "/navUnpack",
        query: { id: id },
      });
    },
    // steam登录
    login(param) {
      this.$SteamOpenidCallback(param)
        .then((res) => {
          this.$setCookies("Authorization", res.data.access_token);
          this.$setCookies("loginFlag", true);
          this.$setCookies("accessexpire", res.data.access_expire);
          this.$setCookies("refreshafter", res.data.refresh_after);
          this.$setCookies("Login-Type", 0);
          this.$store.dispatch("getUserInfo");
          this.$store.dispatch("getUserLucky");
          this.$store.dispatch("getPlayerWin");
          this.$store.dispatch("getNewMailCount");
          this.$store.commit("SET_IS_LOGIN", true);
          this.$store.commit("SET_VISIBLE_LOGIN", false);
          this.$removeCookies("u_steam_id");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取房间列表数据
    getPkRoomList(_sendData) {
      this.$getPkRoomList(_sendData)
        .then((res) => {
          if (res.data.data != null) {
            res.data.data.map((item) => {
              item.translateIndex = 0;
              item.translateX = 0;
              // 对局改版后让列表可重复使用
              item.boxes = item.boxes.map((t) => {
                return { box_id: t };
              });
            });
            this.$store.commit("battle/SET_ROOMS", res.data.data);
            this.$store.commit("battle/SORT_ROOMS_DATA");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goAcn(index) {
      this.anc = index;
    },
  },
};
