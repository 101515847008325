
import { fromatCDNPath } from "@/assets/js/filter";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      bannerList: [],
      activeIndex: 0,
      count: 0,
      timer: null,
      pointList: [],
      colorList: [],
    };
  },
  computed: {
    ...mapGetters([
      "getCommonHistoryExpand",
      "getCommonHistoryHeight",
      "getIsLogin",
      "getIsIdCard",
    ]),
  },
  created() {},
  mounted() {
    this.getBanner();
  },
  methods: {
    init() {
      this.pointList = document.querySelectorAll(".point");
      this.colorList = document.querySelectorAll(".color_bg");
      this.setAni();
    },
    setAni() {
      if (this.pointList[this.activeIndex]) {
        this.pointList[this.activeIndex].style.width = "56px";

        this.startTime = Date.now();
        this.duration = 4800; // 动画过度的总时长，单位毫秒
        this.startWidth = 0; // 初始宽度
        this.endWidth = 56; // 结束时的宽度

        this.colorList[this.activeIndex].className = "color_bg colorAnimate";
      }
    },

    change(activeIndex) {
      this.activeIndex = activeIndex;

      cancelAnimationFrame(this.timer);

      this.colorList.forEach((item) => {
        item.className = "color_bg";
      });
      this.pointList.forEach((item) => {
        item.style.width = "14px";
      });

      this.setAni();
    },
    changeTo(index){
      this.$refs.carousel.setActiveItem(index);
    },
    slideChange(type){
      if(type==1){
        this.$refs.carousel.prev();
      }else{
        this.$refs.carousel.next();
      }
    },
    // 获取banner数据
    getBanner() {
      let data = {
        banner_type: 1,
      };
      this.$siteBanner(data).then((res) => {
        let banner_infos = res.data.banner_infos || [];
        banner_infos.forEach((item) => {
          item.images = fromatCDNPath(item.images);
        });
        this.bannerList = banner_infos;
        console.log(this.bannerList, "bannerList-------------");

        this.$nextTick(() => {
          this.init();
        });
      });
    },
    // 点击banner跳转
    goPage(item) {
      console.log(item, "item");
      // 判断是不是充值界面
      if (item.url == "/pay") {
        if (!this.getIsLogin) {
          this.$store.commit("SET_VISIBLE_LOGIN", true);
          return;
        } else {
          if (!this.getIsIdCard) {
            this.$store.commit("SET_VISIBLE_ID_CARD", true);
            return;
          } else {
            this.$store.commit("SET_IS_SHOW_RECHARGE_DIALOG", true);
            // this.$router.push({
            //   path: "/goodRecharge",
            // });
            return;
          }
        }
      }
      // 判断是不是新年抽券
      if (item.url == "/vipluckym") {
        if (!this.getIsLogin) {
          this.$store.commit("SET_VISIBLE_LOGIN", true);
          return;
        } else {
          if (!this.getIsIdCard) {
            this.$store.commit("SET_VISIBLE_ID_CARD", true);
            return;
          } else {
            this.$store.commit("SET_FREEDAILY_ACTIVE", true);
            return;
          }
        }
      }

      // 判断是不是跳转到个人中心
      if (item.url == "/mine/inventory") {
        if (!this.getIsLogin) {
          this.$store.commit("SET_VISIBLE_LOGIN", true);
          return;
        }
      }
      if (item.url) {
        this.$router.push({
          path: item.url,
        });
      }
    },
  },
};
