
import { fromatCDNPath } from "@/assets/js/filter";
export default {
  props: {
    item: {
      type: Object,
      default: {},
    },
    // bannerVissible:{
    //   type:Boolean,
    //   default:()=>true
    // }
  },
  mounted() {
    console.log(this.tabData, "tabData---------");
  },
  methods: {
    // 箱子点击
    handleClick(child) {
      console.log(this.item, "this.item--------");
      this.$emit("handleClick", child.box_id);
    },
    formatCdnPath(_value) {
      return fromatCDNPath(_value);
    },
  },
};
