import { render, staticRenderFns } from "./index.vue?vue&type=template&id=79e132fe&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=79e132fe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e132fe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GoodBanner: require('/app/components/goodHome/goodBanner.vue').default,GoodHomeTournament: require('/app/components/goodHome/goodHomeTournament.vue').default,GoodHomeBoxList: require('/app/components/goodHome/goodHomeBoxList.vue').default,GoodBtnVoice: require('/app/components/goodCommon/goodBtnVoice.vue').default})
